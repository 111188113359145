import es from "hyphenated-es";
import React from "react";
import Hyphenated from "react-hyphen";
import imgMexico from "../../../assets/img/mapa locaciones.png";
import { useLanguage } from "../../../hooks/useLanguage";

function Map() {
  const { map } = useLanguage();

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full flex flex-col max-w-9xl bg-map bg-center px-4 md:px-8 py-16">
        <div className="w-full flex flex-col lg:flex-row items-center">
          <img
            className="w-full px-8 md:px-20 lg:px-0 lg:w-96 xl:w-120 mx-8"
            src={imgMexico}
          />
          <h2 className="w-full flex-grow leading-normal text-primary-200 text-2xl md:text-4xl lg:text-5xl font-light px-8 md:px-16 2xl:px-24 mt-8 lg:mt-8 lg:mb-8 text-center lg:text-left md:leading-normal lg:leading-normal">
            <Hyphenated language={es}>{map}</Hyphenated>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Map;
