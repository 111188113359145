import imgFlag from "../assets/img/eu.png";
import imgPrincipal from "../assets/img/img principal.png";
import imgStoplight from "../assets/img/semaforizacion.png";

export default {
  _meta: {
    version: 1,
    language: "English",
    tag: "EN",
    flag: imgFlag,
  },
  img: {
    main: imgPrincipal,
    stoplight: imgStoplight,
    video:
      "https://firebasestorage.googleapis.com/v0/b/carserviceapp-df916.appspot.com/o/AssetsCarservice%2Fes.mp4?alt=media&token=9f95ff69-83c1-4c47-bcea-7d675e8b751a",
  },
  brand: {
    name: "CarService",
  },
  navigation: {
    home: "Home",
    benefits: "Benefits",
    contact: "Contact",
    access: "Access",
    demo: "Request a demo",
  },
  whatsappMessage:
    "Hello, I would like to receive more information about CarService!",
  demo: {
    callToAction: "¿Quieres implementar todos estos beneficios en tu negocio?",
    requestDemo: ["Request a demo", "here"],
    title: "Request your demo today!",
    text: "Leave your information and we will contact you shortly",
  },
  hero: {
    tagline: "Your workshop's tecnological associate",
    imageLine: "An integrative system",
    tagline2: "Fit for your service centers",
    automotriz: "Automotive",
    trailers: "Trailers",
    canam: "Canam/Razor/Polaris",
    motocicletas: "Motorcycles",
    electrodomesticos: "Home appliances",
    biomedica: "Biomedical equipment",
    other: "Other",
  },
  benefits: {
    orderslist: "Orders list",
    checklist: "Reception checklist",
    evidence: "Photo and video evidence",
    diagnostics: "Online diagnostic and price quote",
    stoplight: "Categorized repairs evidence",
    quoteTitle: "Service quotation",
    quoteText:
      "SCS your customers will be able to access all their information in order to keep a detailed control of the expenses of each of their units and their upcoming services as well as photographic evidence of the repairs.",
    authTitle: "Customer's authorization",
    authText:
      "SCS your customers will be able to access all their information in order to keep a detailed control of the expenses of each of their units and their upcoming services as well as photographic evidence of the repairs.",
    memoryTitle: "Camera Roll",
    memoryText:
      "SCS your customers will be able to access all their information in order to keep a detailed control of the expenses of each of their units and their upcoming services as well as photographic evidence of the repairs.",
    plots: "Clear infographics",
    profiles: "Suitable for every profile",
    clientcomm: "Constant communication with the customer",
    schedule: "Schedule a service appointment easily",
  },
  video: {
    title: "CARSERVICE VIDEO",
    text: "SCS your customers will be able to access all their information in order to keep a detailed control of the expenses of each of their units and their upcoming services as well as photographic evidence of the repairs.",
  },
  partners: "Business associates",
  map: "We are in all the states of the republic and with presence in Latin America",
  contact: {
    title: "Contact us today!",
    titleAlt: "Contact",
    name: "Name",
    lastName: "Last Name",
    phone: "Telephone",
    company: "Company",
    type: "Type",
    mobile: "Cellphone",
    email: "Email",
    message: "Message",
    send: "Send",
    sending: "Sending...",
    sent: "Thank you! Message sent.",
    error: "There's been an error, please try it again later.",
  },
  footer: {
    description:
      "SEA CARSERVICE IS THE TOOL THAT WILL HELP YOU STREAMLINE YOUR WORKSHOP AND FLEETS IN A COMPREHENSIVE AND INTEGRAL MANNER. YOUR INFORMATION WILL BE STORED IN THE AMAZON SERVICE CLOUD.",
    allRights: "All rights reserved.",
    and: "and",
  },
};
