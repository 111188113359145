import defaultLanguage, { languages } from "../i18n";

const STORAGE_LANGUAGE = "LANGUAGE";
const defaultTag = defaultLanguage?._meta?.tag;

const getOtherLanguagesMeta = (currentLanguageTag = defaultTag) => {
  return languages
    .map((lang) => lang._meta)
    .filter((meta) => meta.tag !== currentLanguageTag);
};

const getLanguageData = (languageTag = defaultTag) =>
  languages.find((lang) => lang._meta.tag === languageTag);

// Create language state object

const getLanguageState = (languageTag) => ({
  current: getLanguageData(languageTag),
  other: getOtherLanguagesMeta(languageTag),
});

// User preferences

let initialLanguage = undefined;

if (localStorage.getItem(STORAGE_LANGUAGE)) {
  initialLanguage = localStorage.getItem(STORAGE_LANGUAGE);
}

// Reducer

export const types = {
  SET_LANGUAGE: "SET_LANGUAGE",
};

const initialState = getLanguageState(initialLanguage);

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_LANGUAGE:
      localStorage.setItem(STORAGE_LANGUAGE, payload);
      return {
        ...state,
        ...getLanguageState(payload),
      };

    default:
      return state;
  }
};

export const actions = {
  setLanguage: (language) => ({
    type: types.SET_LANGUAGE,
    payload: language,
  }),
};
