import React from "react";
import { useLanguage } from "./useLanguage";

import slide1A from "../assets/img/SLD1-A.png";
import slide1B from "../assets/img/SLD1-B.png";
import slide1C from "../assets/img/SLD1-C.png";
import slide1D from "../assets/img/SLD1-D.png";
import slide2A from "../assets/img/SLD2-A.png";
import slide2B from "../assets/img/SLD2-B.png";
import slide2C from "../assets/img/SLD2-C.png";
import slide3A from "../assets/img/SLD3-A.png";
import slide3B from "../assets/img/SLD3-B.png";
import slide3C from "../assets/img/SLD3-C.png";
import slide3D from "../assets/img/SLD3-D.png";

import ben01a from "../assets/img/ben01a.png";
import ben01b from "../assets/img/ben01b.png";
import ben02a from "../assets/img/ben02a.png";
import ben02b from "../assets/img/ben02b.png";
import ben03a from "../assets/img/ben03a.png";
import ben03b from "../assets/img/ben03b.png";
import ben04a from "../assets/img/ben04a.png";
import ben04b from "../assets/img/ben04b.png";

export const SLIDE_LIST_1 = "SLIDE_LIST_1";
export const SLIDE_LIST_2 = "SLIDE_LIST_2";
export const SLIDE_LIST_3 = "SLIDE_LIST_3";

export function useBenefits(slideList) {
  const { benefits: language } = useLanguage();

  switch (slideList) {
    case SLIDE_LIST_1:
      return [
        {
          caption: language?.orderslist,
          src: slide1A,
        },
        {
          caption: language?.checklist,
          src: slide1B,
        },
        {
          caption: language?.evidence,
          src: slide1C,
        },
        {
          caption: language?.diagnostics,
          src: slide1D,
        },
      ];
    case SLIDE_LIST_2:
      return [
        {
          src: slide2A,
        },
        {
          src: slide2B,
        },
        {
          src: slide2C,
        },
      ];
    case SLIDE_LIST_3:
      return [
        {
          icon: ben01b,
          iconSelected: ben01a,
          caption: language?.plots,
          src: slide3A,
        },
        {
          icon: ben02b,
          iconSelected: ben02a,
          caption: language?.profiles,
          src: slide3B,
        },
        {
          icon: ben03b,
          iconSelected: ben03a,
          caption: language?.clientcomm,
          src: slide3C,
        },
        {
          icon: ben04b,
          iconSelected: ben04a,
          caption: language?.schedule,
          src: slide3D,
        },
      ];
    default:
      return [];
  }
}
