import React from "react";
import Hyphenated from "react-hyphen";
import es from "hyphenated-es";
import { useLanguage } from "../../../hooks/useLanguage";

export default () => {
  const { benefits: language, img } = useLanguage();

  return (
    <div className="w-full bg-ngray-300 flex flex-row justify-center">
      <div className="w-full flex flex-col items-center bg-right sm:bg-center xl:bg-left bg-evidence_sm sm:bg-evidence bg-cover bg-no-repeat 2xl:max-w-screen-4xl">
        {/* TODO: Fix responsive layout */}
        <div className="w-full max-w-9xl my-8 sm:my-20 md:my-28 xl:my-30 2xl:my-36 4xl:my-48 flex flex-row justify-end transition-all">
          <div className="w-1/2 sm:w-1/3 xl:w-auto flex flex-col pr-8 2xl:pr-12 justify-center items-end 2xl:items-center">
            <h2 className="flex xl:w-120 text-right 2xl:text-center leading-normal text-primary-700 text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-normal mb-8">
              <Hyphenated language={es}>{language.stoplight}</Hyphenated>
            </h2>
            <div className="sm:w-48 md:w-64 xl:w-72">
              <img className="object-cover" src={img.stoplight} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
