import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SLIDE_LIST_1, useBenefits } from "../../../hooks/useBenefits";

export default () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const benefits = useBenefits(SLIDE_LIST_1);

  const Benefit = ({ item, idx }) => {
    let classes =
      "py-4 mb-2 md:mb-4 px-4 md:px-8 text-xl md:text-2xl transition-all flex flex-row justify-between cursor-pointer text-center md:text-left";
    let chevron = null;

    if (idx === activeSlide) {
      chevron = <span className="hidden md:flex ml-4 font-bold">&gt;</span>;
      classes += " text-white bg-primary-300 font-medium";
    } else {
      classes += " text-primary-800 bg-ngray-200";
    }

    const handleClick = () => {
      if (activeSlide !== idx) setActiveSlide(idx);
    };

    return (
      <div className={classes} onClick={handleClick}>
        <span className="w-full">{item.caption}</span>
        {chevron}
      </div>
    );
  };

  return (
    <div id="benefits" className="w-full flex flex-col items-center">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 max-w-9xl my-6 sm:my-12 md:my-24">
        <div className="w-full flex flex-col items-stretch px-4 md:px-8 xl:px-20 justify-center mb-4 md:mb-12 lg:mb-0">
          {benefits.map((item, idx) => (
            <Benefit key={idx} item={item} idx={idx} />
          ))}
        </div>
        <div className="w-full flex flex-col px-8 xl:px-20">
          <Carousel
            animationHandler="fade"
            swipeable={false}
            selectedItem={activeSlide}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
          >
            {benefits.map((item, idx) => (
              <div key={`slide-${idx}`}>
                <img src={item.src} alt={item.caption} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
