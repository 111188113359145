import es from "hyphenated-es";
import React from "react";
import Hyphenated from "react-hyphen";
import Modal from "./Modal";

function LongTextModal({ title, text = "", open, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-row items-start justify-between sm:px-4 sm:pt-4">
        <h2 className="w-full leading-normal text-primary-700 text-xl md:text-3xl font-normal mb-4 flex-grow">
          {title}
        </h2>
        <div
          className="text-2xl md:text-3xl sm:px-4 cursor-pointer"
          onClick={onClose}
        >
          &times;
        </div>
      </div>
      <p className=" overflow-y-auto text-sm sm:text-base sm:px-4">
        <Hyphenated language={es}>
          {text &&
            text.split("\n").map((item, index) => (
              <React.Fragment key={index}>
                {item}
                <br />
              </React.Fragment>
            ))}
        </Hyphenated>
      </p>
    </Modal>
  );
}

export default LongTextModal;
