import React from "react";
import DemoButton from "../../../components/DemoButton";
import { useLanguage } from "../../../hooks/useLanguage";

function Demo() {
  const { demo } = useLanguage();

  return (
    <div className="w-full flex flex-col items-center bg-ngray-50 px-4 md:px-8 py-16">
      <div className="w-full flex flex-col max-w-9xl text-center">
        <h2 className="w-full leading-normal text-primary-700 text-4xl md:text-5xl font-normal mb-8">
          {demo?.callToAction}
        </h2>
        <div className="w-full max-w-10xl text-center">
          <div className="flex flex-col items-center mx-2">
            <DemoButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demo;
