import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLanguage } from "../../../hooks/useLanguage";
import httpPost from "../../../services/httpPost";

export default () => {
  const [nombre, setName] = useState("");
  const [telefono, setPhone] = useState("");
  const [correo, setEmail] = useState("");
  const [mensaje, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const { contact } = useLanguage();

  const inputs = [
    {
      value: nombre,
      setValue: setName,
      placeholder: contact?.name,
      type: "text",
    },
    {
      value: telefono,
      setValue: setPhone,
      placeholder: contact?.phone,
      type: "tel",
    },
    {
      value: correo,
      setValue: setEmail,
      placeholder: contact?.email,
      type: "email",
    },
  ];

  const clearFields = () => {
    setName("");
    setPhone("");
    setEmail("");
    setMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSending) return;
    setIsSending(true);

    httpPost("https://taller.carservice.mx/resources/contacto.aspx", {
      nombre,
      telefono,
      correo,
      mensaje,
    })
      .then(() => {
        toast.success(contact?.sent);
        clearFields();
      })
      .catch(() => toast.error(contact?.error))
      .finally(() => setIsSending(false));
  };

  const SendButton = () => {
    const sendingClasses = isSending
      ? "bg-primary-900 cursor-loading"
      : "bg-primary-500 cursor-pointer hover:bg-primary-720";
    return (
      <div className="flex flex-col items-center mx-2">
        <input
          type="submit"
          value={isSending ? contact?.sending : contact?.send}
          className={`text-2xl uppercase w-full md:w-1/3 text-white font-normal my-4 py-4 rounded-lg transition-colors ${sendingClasses}`}
        />
      </div>
    );
  };

  return (
    <div
      id="contact"
      className="w-full flex flex-col items-center bg-primary-100 px-4 md:px-8 py-12 md:py-16"
    >
      <form
        className="w-full flex flex-col max-w-9xl text-center"
        onSubmit={handleSubmit}
      >
        <h2 className="w-full leading-normal text-white text-4xl md:text-5xl font-light mb-6 md:mb-16">
          {contact?.title}
        </h2>
        <div className="grid md:grid-cols-3">
          {inputs.map(({ value, setValue, placeholder, type }) => (
            <div
              className="p-3 bg-white rounded-lg mx-2 my-2 md:my-0"
              key={placeholder}
            >
              <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                type={type}
                placeholder={placeholder}
                required
                className="w-full text-xl"
              />
            </div>
          ))}
        </div>
        <div className="p-3 bg-white rounded-lg mx-2 my-2 md:my-4">
          <textarea
            className="w-full text-xl resize-none"
            rows="3"
            placeholder={contact?.message}
            value={mensaje}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <SendButton />
      </form>
      <ToastContainer />
    </div>
  );
};
