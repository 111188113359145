import es from "hyphenated-es";
import React from "react";
import Hyphenated from "react-hyphen";
import { useLanguage } from "../../../hooks/useLanguage";

export default () => {
  const { video, img } = useLanguage();

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 max-w-9xl my-12 md:my-24 md:mb-32">
        <div className="w-full flex flex-col items-right px-8 xl:px-20 justify-center text-center lg:text-left">
          <h2 className="w-full leading-normal text-primary-700 text-4xl md:text-5xl font-normal mb-8">
            {video?.title}
          </h2>
        </div>
        <div className="w-full flex flex-col items-stretch px-8 xl:px-20 mt-12 md:mt-0 justify-center">
          <video controls>
            <source src={img?.video} />
          </video>
        </div>
      </div>
    </div>
  );
};
