import imgFlag from "../assets/img/mx.png";
import imgPrincipal from "../assets/img/img principal.png";
import imgStoplight from "../assets/img/semaforizacion.png";

export default {
  _meta: {
    version: 1,
    language: "Español",
    tag: "ES",
    flag: imgFlag,
  },
  img: {
    main: imgPrincipal,
    stoplight: imgStoplight,
    video:
      "https://firebasestorage.googleapis.com/v0/b/carserviceapp-df916.appspot.com/o/AssetsCarservice%2Fes.mp4?alt=media&token=9f95ff69-83c1-4c47-bcea-7d675e8b751a",
  },
  brand: {
    name: "CarService",
  },
  navigation: {
    home: "Inicio",
    benefits: "Beneficios",
    contact: "Contacto",
    access: "Acceso",
    demo: "Solicita tu demo",
  },
  demo: {
    callToAction: "¿Quieres implementar todos estos beneficios en tu negocio?",
    requestDemo: ["Solicita un demo", "aquí"],
    title: "¡Solicita tu demo hoy!",
    text: "Deje sus datos y nosotros nos comunicaremos en brevedad",
  },
  whatsappMessage: "Hola, me gustaria tener más información de CarService!",
  hero: {
    tagline: "El Socio tecnológico de tu taller",
    imageline: "Un sistema integral",
    tagline2: "Listo para tu tipo de centros de servicio",
    automotriz: "Automotriz",
    trailers: "Trailers",
    canam: "Canam/Razor/Polaris",
    motocicletas: "Motocicletas",
    electrodomesticos: "Electrodomésticos",
    biomedica: "Biomédica",
    other: "Otros",
  },
  benefits: {
    orderslist: "Lista de órdenes",
    checklist: "Checklist de recepción",
    evidence: "Evidencias fotográficas y de video",
    diagnostics: "Diagnóstico y presupuesto en línea",
    stoplight: "Evidencia de fallas semaforizadas",
    quoteTitle: "Cotización de servicios",
    quoteText:
      "SCS tus clientes podrán accesar toda su información con el fin de llevar un control detallado de los gastos de cada una de sus unidades y sus próximos servicios y las evidencias fotográficas de las reparaciones.",
    authTitle: "Autorización del cliente",
    authText:
      "SCS tus clientes podrán accesar toda su información con el fin de llevar un control detallado de los gastos de cada una de sus unidades y sus próximos servicios y las evidencias fotográficas de las reparaciones.",
    memoryTitle: "Memoria Fotográfica",
    memoryText:
      "SCS tus clientes podrán accesar toda su información con el fin de llevar un control detallado de los gastos de cada una de sus unidades y sus próximos servicios y las evidencias fotográficas de las reparaciones.",
    plots: "Gráficas informativas",
    profiles: "Para todos los perfiles",
    clientcomm: "Comunicación con el cliente",
    schedule: "Agenda tus servicios fácilmente",
  },
  video: {
    title: "VIDEO CARSERVICE",
    text: "SCS tus clientes podrán accesar toda su información con el fin de llevar un control detallado de los gastos de cada una de sus unidades y sus próximos servicios y las evidencias fotográficas de las reparaciones.",
  },
  partners: "Socios comerciales",
  map: "Estamos en todos los estados de la república y con presencia en América Latina",
  contact: {
    title: "¡Contáctanos hoy!",
    titleAlt: "Contacto",
    name: "Nombre",
    lastName: "Apellido",
    phone: "Telefóno",
    company: "Empresa",
    type: "Tipo",
    mobile: "Celular",
    email: "Correo",
    message: "Mensaje",
    send: "Enviar",
    sending: "Enviando...",
    sent: "¡Gracias! Mensaje enviado.",
    error: "Ha ocurrido un error, por favor intente más tarde.",
  },
  footer: {
    description:
      "SEA CARSERVICE ES LA HERRAMIENTA QUE TE AYUDARA A EFICIENTIZAR EL TALLER Y FLOTILLAS DE UNA MANERA INTEGRAL. TU INFORMACION SERA GUARDADA EN LA NUBE DEL SERVICIO DE AMAZON.",
    allRights: "Todos los derechos Reservados.",
    and: "y",
  },
};
