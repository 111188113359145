import React from "react";
import { Route, Switch } from "react-router";
import DemoPage from "../pages/demo/DemoPage";
import LandingPage from "../pages/landing/LandingPage";

export default () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/demo" component={DemoPage} />
        <Route path="*" component={LandingPage} />
      </Switch>
    </>
  );
};
