import React, { useRef } from "react";

function Input({ label, name, value, onChange, type }) {
  const inputRef = useRef(null);

  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="my-2 text-white text-lg">
        {label}
      </label>
      <div
        className="px-2 h-12 bg-white rounded-lg my-1 md:my-0"
        onClick={() => inputRef.current?.focus()}
      >
        <input
          ref={inputRef}
          value={value}
          name={name}
          onChange={onChange}
          type={type}
          placeholder={label}
          required
          className="p-2 w-full text-xl"
        />
      </div>
    </div>
  );
}

export default Input;
