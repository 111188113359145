import React, { useEffect, useState } from "react";
import { useLanguage } from "../../hooks/useLanguage";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "../../components/Dropdown";
import Input from "../../components/Input";
import httpPost from "../../services/httpPost";
import "react-toastify/dist/ReactToastify.css";

const initialFormData = {
  nombre: "",
  apellido: "",
  correo: "",
  telefono: "",
  empresa: "",
  tipo: "",
  tipoOtro: "",
};

export default function DemoPage() {
  const [formData, setFormData] = useState(initialFormData);
  const [isSending, setIsSending] = useState(false);

  const { contact, demo, hero } = useLanguage();

  const options = [
    { text: hero?.automotriz, value: "AUTOMOTRIZ" },
    { text: hero?.trailers, value: "TRAILER" },
    { text: hero?.canam, value: "CANAM/RAZOR/POLARIS" },
    { text: hero?.motocicletas, value: "MOTOCICLETAS" },
    { text: hero?.electrodomesticos, value: "ELECTRODOMESTICOS" },
    { text: hero?.biomedica, value: "BIOMEDICA" },
    { text: hero?.other, value: "OTROS" },
  ];

  useEffect(() => {
    setFormData({
      ...formData,
      tipo: options[0],
    });
  }, []);

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSending) return;
    setIsSending(true);

    httpPost("https://taller.carservice.mx/resources/contacto.aspx", formData)
      .then(() => {
        toast.success(contact?.sent);
        setFormData(initialFormData);
      })
      .catch(() => toast.error(contact?.error))
      .finally(() => setIsSending(false));
  };

  const SendButton = () => {
    const sendingClasses = isSending
      ? "cursor-loading"
      : "bg-cyan-500 cursor-pointer";
    return (
      <div className="flex flex-col items-center mt-2">
        <input
          type="submit"
          value={isSending ? contact?.sending : contact?.send}
          className={`text-2xl w-full text-primary-900 font-normal my-4 py-2 rounded-lg transition-colors btn-demo ${sendingClasses}`}
        />
      </div>
    );
  };
  return (
    <div className="w-full bg-primary-770 flex flex-row justify-center">
      <div className="w-full flex flex-row justify-center bg-left lg:bg-demo bg-cover bg-no-repeat 2xl:max-w-screen-4xl">
        <div className="w-0 lg:w-1/2 xl:w-1/3"></div>
        <div className="w-full lg:w-1/2 xl:w-2/3 flex flex-col 2xl:pr-12 mx-8 my-12 lg:my-32 justify-center items-start">
          <h2 className="leading-normal text-white text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-medium mb-8">
            {demo?.title}
          </h2>
          <p className="text-white font-light text-lg">{demo?.text}</p>
          <form className="w-full flex flex-col" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 gap-x-3">
              <Input
                onChange={handleChange}
                value={formData.nombre}
                label={contact?.name}
                name="nombre"
                type="text"
              />
              <Input
                onChange={handleChange}
                value={formData.apellido}
                label={contact?.lastName}
                name="apellido"
                type="text"
              />
              <Input
                onChange={handleChange}
                value={formData.correo}
                label={contact?.email}
                name="correo"
                type="email"
              />
              <Input
                onChange={handleChange}
                value={formData.telefono}
                label={contact?.phone}
                name="telefono"
                type="tel"
              />
              <Input
                onChange={handleChange}
                value={formData.empresa}
                label={contact?.company}
                name="empresa"
                type="text"
              />
              <Dropdown
                onChange={handleChange}
                options={options}
                label={contact?.type}
                name="tipo"
              />
              <div></div>
              {formData.tipo === "OTROS" ? (
                <>
                  <Input
                    onChange={handleChange}
                    value={formData.tipoOtro}
                    label={contact?.type}
                    name="tipoOtro"
                    type="text"
                  />
                  <div />
                </>
              ) : null}
              <SendButton />
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
