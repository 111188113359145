import React from "react";
import { useLanguage } from "../../../hooks/useLanguage";

import marca01 from "../../../assets/img/marca01.png";
import marca02 from "../../../assets/img/marca02.png";
import marca03 from "../../../assets/img/marca03.png";

function open(url){
  if(url){
    window.open(url,"_blank")
  }
}

function Partners() {
  const { partners } = useLanguage();

  const marcas = [
    {img:marca01}, 
    {
      img:marca02, 
      url:'https://redbosch.carservice.mx/'
    },
    {img:marca03}
    ];

  return (
    <div className="w-full flex flex-col items-center bg-ngray-200 px-4 md:px-8 py-16">
      <div className="w-full flex flex-col max-w-9xl text-center">
        <h2 className="w-full leading-normal text-primary-700 text-4xl md:text-5xl font-light mb-8">
          {partners}
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center">
          {marcas.map((src, idx) => (
            <img
              className="object-contain w-48 mx-14 my-3 md:mb-0"
              onClick={()=> open(src.url)}
              key={idx}
              src={src.img}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Partners;
