import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/useLanguage";
import "./common.scss";

function DemoButton() {
  const { demo } = useLanguage();

  return (
    <Link
      value="A"
      to="/demo"
      className="text-3xl w-full md:w-1/3 bg-cyan-500 text-white font-normal my-4 py-4 rounded-lg transition-colors cursor-pointer btn-demo shadow"
    >
      <span>{demo?.requestDemo[0]}</span>&nbsp;
      <span className="font-bold">{demo?.requestDemo[1]}</span>
    </Link>
  );
}

export default DemoButton;
