import axios from "axios";
const qs = require("querystring");

export default async function httpPost(endpoint, body) {
  return axios.post(endpoint, qs.stringify(body), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  });
}
