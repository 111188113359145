import React from "react";
import Benefits1 from "./benefits/Benefits1";
import Benefits2 from "./benefits/Benefits2";
import Benefits3 from "./benefits/Benefits3";
import Benefits4 from "./benefits/Benefits4";
import Benefits5 from "./benefits/Benefits5";
import Benefits6 from "./benefits/Benefits6";
import Contact from "./contact/Contact";
import Demo from "./demo/Demo";
import Hero from "./hero/Hero";
import Map from "./map/Map";
import Partners from "./partners/Partners";
import Video from "./video/Video";

export default () => {
  return (
    <>
      <Hero />
      <Benefits1 />
      <Benefits2 />
      <Benefits3 />
      <Benefits4 />
      <Benefits5 />
      <Benefits6 />
      <Video />
      <Demo />
      <Partners />
      <Map />
      <Contact />
    </>
  );
};
