import React, { useRef } from "react";

function Dropdown({ label, name, onChange, options }) {
  return (
    <div className="flex flex-col">
      <label htmlFor={name} className="my-2 text-white text-lg">
        {label}
      </label>
      <div className="px-2 py-1 h-12 bg-white rounded-lg my-1 md:my-0">
        <select
          name={name}
          onChange={onChange}
          placeholder={label}
          required
          className="p-2 w-full text-xl"
        >
          {options?.map((option) => (
            <option
              key={option.value}
              value={option.value}
              selected={option.selected}
            >
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Dropdown;
