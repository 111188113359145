import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SLIDE_LIST_3, useBenefits } from "../../../hooks/useBenefits";

export default () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const benefits = useBenefits(SLIDE_LIST_3);

  const Benefit = ({ item, idx }) => {
    let textClasses = "";
    let containerClasses = "";
    let icon;

    if (idx === activeSlide) {
      containerClasses += "bg-primary-600";
      icon = item.iconSelected;
    } else {
      textClasses += "opacity-60";
      containerClasses += "bg-primary-700";
      icon = item.icon;
    }

    const handleClick = () => {
      if (activeSlide !== idx) setActiveSlide(idx);
    };

    return (
      <div
        onClick={handleClick}
        className="mt-2 lg:mt-4 mb-2 px-4 md:px-8 text-xl md:text-2xl transition-all flex flex-col justify-between cursor-pointer text-center"
      >
        <div
          className={`hidden lg:flex flex-row justify-center items-center py-12 ${containerClasses} `}
        >
          <img className="w-24" src={icon} />
        </div>

        <span
          className={`bg-primary-750 p-4 lg:p-0 lg:bg-transparent w-full text-white lg:mt-2 ${textClasses}`}
        >
          {item.caption}
        </span>
      </div>
    );
  };

  return (
    <div id="benefits" className="w-full flex flex-col items-center">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2">
        <div className="w-full flex flex-row px-4 xl:px-16 lg:py-8 items-center justify-center lg:justify-end bg-primary-700">
          <Carousel
            className="lg:max-w-2xl"
            animationHandler="fade"
            swipeable={false}
            selectedItem={activeSlide}
            showIndicators={false}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
          >
            {benefits.map((slide, idx) => (
              <div
                className="flex flex-col justify-center"
                key={`slide-${idx}`}
              >
                <img
                  className="object-cover"
                  src={slide.src}
                  alt={slide.caption}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="w-full bg-gradient-to-b bg-primary-700 lg:from-primary-750 lg:to-primary-800 px-4 md:px-8 xl:px-16 py-2 lg:py-20 flex flex-row justify-center lg:justify-start">
          <div className="w-full lg:max-w-2xl h-full grid lg:grid-cols-2">
            {benefits.map((item, idx) => (
              <Benefit key={idx} item={item} idx={idx} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
