import React, { useState } from "react";
import Hyphenated from "react-hyphen";
import es from "hyphenated-es";
import LongTextModal from "./LongTextModal";

import imgLogo from "../assets/img/logo footer.png";
import icoTel from "../assets/img/tel.png";
import icoCel from "../assets/img/cel.png";
import icoMail from "../assets/img/mail.png";
import { useLanguage } from "../hooks/useLanguage";

export default () => {
  const { footer, contact } = useLanguage();

  const contactLines = [
    { icon: icoTel, label: contact?.phone, text: "6441206610" },
    {
      icon: icoCel,
      label: contact?.mobile,
      text: "6442360534, 6621140472",
    },
    { icon: icoMail, label: contact?.email, text: "info@ebuzon.mx" },
  ].filter((item) => item.text);

  const ContactLine = ({ icon, label, text }) => {
    return (
      <>
        <li className="text-white mb-3 flex flex-row text-lg md:text-base items-center">
          <img className="mr-2 w-5 h-5 object-contain" src={icon} />
          <span className="font-light">{label}:</span>
          <span className="hidden md:block ml-2 font-medium">{text}</span>
        </li>
        <li className="md:hidden text-white mb-3 flex flex-row text-lg md:text-base items-center">
          <span className="font-medium">{text}</span>
        </li>
      </>
    );
  };

  return (
    <div
      id="contact"
      className="w-full flex flex-col items-center bg-primary-900 px-8 py-12 md:py-16"
    >
      <div className="w-full flex flex-col lg:flex-row max-w-9xl justify-between">
        <div className="flex flex-col flex-grow">
          <img
            src={imgLogo}
            alt="CarService"
            role="presentation"
            className="inline-block text-white w-64 object-left"
          />
          <p className="w-full leading-loose text-white text-lg md:text-xl font-light mt-8 mb-4 md:mb-12">
            <Hyphenated language={es}>{footer?.description}</Hyphenated>
          </p>
        </div>
        {contactLines.length > 0 ? (
          <div className="flex flex-col lg:ml-16 min-w-max">
            <h3 className="hidden md:block uppercase font-normal text-2xl text-white">
              {contact?.titleAlt}
            </h3>
            <ul className="md:mt-4 lg:mt-6 mb-4">
              {contactLines.map(({ icon, label, text }) => (
                <ContactLine
                  key={label}
                  icon={icon}
                  label={label}
                  text={text}
                />
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      <hr className="border-primary-100 border-solid border-1 w-full max-w-10xl mb-8" />
      <div className="w-full flex flex-col lg:flex-row max-w-9xl justify-between">
        <p className="flex-grow text-white text-lg lg:text-xl mb-3 font-light">
          {footer?.allRights}
        </p>
      </div>
    </div>
  );
};
