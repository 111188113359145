import React from "react";
import imgLogo from "../assets/img/logo-blanco copy 4 1.png";
import Language from "./Language";
import { useLanguage } from "../hooks/useLanguage";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./common.scss";

export default () => {
  const { brand, navigation } = useLanguage();

  return (
    <div className=" top-0 z-30 bg-primary-500 w-full md:w-auto md:relative pt-3 md:pt-6 pb-3 md:pb-6 px-4 sm:px-6 lg:px-8 mx-auto flex justify-center">
      <nav
        className="relative flex-grow flex items-center justify-between md:h-10 w-full max-w-10xl"
        aria-label="Global"
      >
        <div className="flex items-center">
          <div className="flex items-center justify-between ">
            <Link className="cursor-pointer" to="/">
              <span className="sr-only">{brand?.name || "CarService"}</span>
              <img
                src={imgLogo}
                alt="CarService"
                role="presentation"
                className="inline-block text-white w-40 md:w-64 object-left"
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-row items-start md:items-center ml-4 md:ml-10 md:pr-4 md:space-x-5">
          <HashLink
            className="hidden md:flex text-white font-light cursor-pointer hover:underline"
            smooth
            to="/#benefits"
          >
            {navigation?.benefits}
          </HashLink>
          <HashLink
            className="hidden md:flex text-white font-light cursor-pointer hover:underline"
            smooth
            to="/#contact"
          >
            {navigation?.contact}
          </HashLink>
          <div className="cursor-pointer font-medium bg-primary-300 px-2 py-1 md:px-4 mr-2 md:mr-0 rounded-lg">
            <a
              className="text-white cursor-pointer text-sm md:text-base"
              target={"_self"}
              href={"https://taller.carservice.mx"}
            >
              {navigation?.access}
            </a>
          </div>
          <div className="cursor-pointer font-medium bg-cyan-500 px-2 py-1 md:px-4 mr-2 md:mr-0 rounded-lg hidden lg:flex btn-demo">
            <Link
              className="text-primary-720 cursor-pointer text-sm md:text-base"
              to="/demo"
            >
              {navigation?.demo}
            </Link>
          </div>

          <Language />
        </div>
      </nav>
    </div>
  );
};
