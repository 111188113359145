import React from "react";

export default ({ activeSlide, index, onClick }) => {
  let classes = "w-4 h-4 md:w-3 md:h-3 rounded-full mx-4 cursor-pointer ";

  if (index === activeSlide) {
    classes += " bg-primary-300";
  } else {
    classes += " bg-ngray-500 opacity-55";
  }

  return <div className={classes} onClick={onClick}></div>;
};
