import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Hyphenated from "react-hyphen";
import es from "hyphenated-es";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import CarouselDot from "../../../components/CarouselDot";
import { SLIDE_LIST_2, useBenefits } from "../../../hooks/useBenefits";
import { useLanguage } from "../../../hooks/useLanguage";

export default () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const { benefits: language } = useLanguage();

  const updateSlide = (index) => {
    if (index !== activeSlide) setActiveSlide(index);
  };

  const slides = useBenefits(SLIDE_LIST_2);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 max-w-9xl my-12 md:my-20">
        <div className="w-full flex flex-col items-stretch px-8 xl:px-20 justify-center mb-6 md:mb-12 lg:mb-0">
          <h2 className="text-primary-700 text-4xl md:text-5xl mb-8 font-normal text-center md:text-left">
            {language.quoteTitle}
          </h2>
          <p className="text-primary-700 text-lg md:text-xl md:leading-loose md:pr-8 text-center md:text-left">
            <Hyphenated language={es}>{language.quoteText}</Hyphenated>
          </p>
        </div>
        <div className="w-full flex flex-col px-8 xl:px-20">
          <Carousel
            autoPlay={true}
            interval={3000}
            swipeable={true}
            emulateTouch={true}
            infiniteLoop={true}
            selectedItem={activeSlide}
            onChange={updateSlide}
            showIndicators={false}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
          >
            {slides.map((slide, idx) => (
              <div key={idx}>
                <img src={slide.src} />
              </div>
            ))}
          </Carousel>
          <div className="flex flex-row items-center justify-center mt-8 md:mt-16">
            {slides.map((_, idx) => (
              <CarouselDot
                activeSlide={activeSlide}
                key={idx}
                index={idx}
                onClick={() => updateSlide(idx)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
