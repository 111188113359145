import React from "react";
import Hyphenated from "react-hyphen";
import es from "hyphenated-es";
import tds1 from "../../../assets/img/tds01.png";
import tds2 from "../../../assets/img/tds02.png";
import tds3 from "../../../assets/img/tds03.png";
import tds4 from "../../../assets/img/tds04.png";
import tds5 from "../../../assets/img/tds05.png";
import tds6 from "../../../assets/img/tds06.png";
import { useLanguage } from "../../../hooks/useLanguage";
import DemoButton from "../../../components/DemoButton";

export default () => {
  const { hero, img } = useLanguage();
  const images = [
    {
      caption: hero.automotriz,
      src: tds1,
    },
    {
      caption: hero.trailers,
      src: tds2,
    },
    {
      caption: hero.canam,
      src: tds3,
    },
    {
      caption: hero.motocicletas,
      src: tds4,
    },
    {
      caption: hero.electrodomesticos,
      src: tds5,
    },
    {
      caption: hero.biomedica,
      src: tds6,
    },
  ];

  return (
    <div
      className="relative bg-gradient-to-b from-primary-750 to-primary-800 flex flex-col items-center"
      id="top"
    >
      <div className="w-full bg-partner_sm sm:bg-partner bg-top 2xl:bg-bottom bg-cover center bg-no-repeat text-center pt-20">
        <div className="row">
          <h1 className="text-4xl md:text-5xl lg:text-6xl text-white font-light">
            {hero?.tagline}
          </h1>
        </div>
        <div className="row flex justify-center pt-12">
          <img
            src={img?.main}
            alt="Un sistema integral"
            className="w-auto px-2 md:px-16 md:w-8/12 lg:w-6/12  lg:max-w-6xl md:-mb-48 object-contain lg:pb-12"
          />
        </div>
      </div>

      <div className="w-full text-center md:pt-40">
        <div className="row pt-8">
          <h1 className="text-4xl xs:text-5xl lg:text-6xl text-white font-light px-4">
            {hero?.tagline2}
          </h1>
        </div>
      </div>

      <div className="w-full max-w-10xl mt-8 md:mt-16 px-8 md:px-12 xl:px-24 pb-16 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-8 md:gap-12">
        {images.map((image, idx) => (
          <div
            key={idx}
            className="mb-2 md:mb-8 xl:mb-0 flex flex-col text-center items-center"
          >
            <img
              src={image.src}
              className="opacity-100 md:opacity-40 hover:opacity-100 transition-opacity"
            />
            <Hyphenated language={es}>
              <span className="text-white font-normal text-lg md:text-2xl xl:text-xl 2xl:text-2xl uppercase ">
                {image.caption}
              </span>
            </Hyphenated>
          </div>
        ))}
      </div>

      <div className="w-full max-w-10xl text-center pb-16">
        <div className="flex flex-col items-center mx-2">
          <DemoButton />
        </div>
      </div>
    </div>
  );
};
