import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import chevron from "../assets/img/chevron-down.png";
import { actions } from "../services/language";

const hideChevronOnTwo = process.env.REACT_APP_2LANG_CHEVRON !== "true";

function Language() {
  const { current, other } = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    if (other.length == 0) return;
    if (other.length == 1 && hideChevronOnTwo)
      return dispatch(actions.setLanguage(other[0]?.tag));

    setOpen(!open);
  };

  const setLanguage = (tag) => {
    dispatch(actions.setLanguage(tag));
    setOpen(false);
  };

  const Chevron = () => {
    const flippedClasses = open
      ? " transform rotate-180 transition-transform"
      : "";

    if (hideChevronOnTwo && other?.length == 1) {
      return null;
    }

    return (
      <img
        className={`hidden lg:inline-block object-contain ml-2 w-3 ${flippedClasses}`}
        src={chevron}
      />
    );
  };

  const LanguageListItem = ({ flag, tag, showChevron, onClick, isWide }) => {
    const wideClass = isWide ? " lg:pr-5" : "";
    return (
      <li
        className={`flex flex-row py-2 w-full ${wideClass}`}
        onClick={onClick}
      >
        <img className="object-contain lg:mr-2 w-5" src={flag} />
        <span className="hidden lg:flex text-white text-sm">{tag}</span>
        {showChevron ? <Chevron /> : null}
      </li>
    );
  };

  const LanguageList = ({ languages = [] }) => {
    if (!open) return null;

    return (
      <div className="z-10 origin-top-right absolute right-0 mt-2 lg:mt-1 rounded-lg bg-primary-720 md:mr-4">
        <div className="py-1 text-white text-sm px-4 lg:py-2">
          {languages.map((item, idx) => (
            <LanguageListItem
              key={item.tag + idx}
              flag={item.flag}
              tag={item.tag}
              isWide={true}
              onClick={() => setLanguage(item.tag)}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className="inline-block bg-primary-720 hover:bg-primary-900 h-8 lg:h-auto w-auto lg:px-4 justify-center cursor-pointer rounded-lg"
      onClick={toggleMenu}
    >
      <div className="relative flex flex-row align-center px-4 lg:px-0">
        <LanguageListItem
          flag={current?._meta?.flag}
          tag={current?._meta?.tag}
          showChevron={true}
        />
      </div>

      <LanguageList languages={other} />
    </div>
  );
}

export default Language;
