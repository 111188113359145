import es from "hyphenated-es";
import React from "react";
import Hyphenated from "react-hyphen";
import imgMemory from "../../../assets/img/memoria fotografica.png";
import { useLanguage } from "../../../hooks/useLanguage";

export default () => {
  const { benefits: language } = useLanguage();

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 max-w-9xl my-12">
        <div className="w-full flex flex-col items-right px-8 xl:px-20 justify-center text-center lg:text-left">
          <h2 className="w-full leading-normal text-primary-700 text-4xl md:text-5xl font-normal mb-8">
            {language.memoryTitle}
          </h2>
          <p className="text-primary-700 text-lg md:text-xl md:leading-loose md:pr-8">
            <Hyphenated language={es}>{language.memoryText}</Hyphenated>
          </p>
        </div>
        <div className="w-full flex flex-col items-stretch px-8 xl:px-20 mt-12 md:md-0 justify-center">
          <img className="object-contain" src={imgMemory} />
        </div>
      </div>
    </div>
  );
};
