import React, { useState } from "react";
import chevron from "../assets/img/chevron-down.png";
import imgCall from "../assets/img/llamada.png";
import imgWhats from "../assets/img/whatsapp.png";
import imgMail from "../assets/img/email.png";
import { useLanguage } from "../hooks/useLanguage";

const phoneNumber = "+526441206610";
const whatsappNumber = "+526442360534";
const toAddress = "info@ebuzon.mx, soporte@ebuzon.mx, cholguin@hotmail.com";

function ContactWidget() {
  const [open, setOpen] = useState(true);

  const toggleOpen = () => setOpen(!open);

  const { whatsappMessage } = useLanguage();

  const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
    whatsappMessage
  )}`;
  const mailtoUrl = `mailto:${toAddress}`;

  const chevronRotateClass = open ? "-rotate-90" : "rotate-90";
  const menuOpenClass = open ? "pl-3 py-2" : "";

  if (!phoneNumber && !whatsappNumber && !toAddress) return null;

  return (
    <div className="fixed right-0 h-full z-20 flex flex-col justify-center pointer-events-none">
      <div
        className={`bg-primary-720 flex flex-col shadow-lg md:shadow-none pointer-events-auto cursor-pointer rounded-tl-3xl rounded-bl-3xl transition-all hover:bg-primary-900 items-center ${menuOpenClass}`}
      >
        <div className="px-4 py-6" onClick={() => toggleOpen()}>
          <img
            src={chevron}
            className={`transform ${chevronRotateClass} h-4 w-4 md:h-6 md:w-6 object-contain transition-transform`}
          />
        </div>
        {open && phoneNumber ? (
          <a href={`tel:${phoneNumber}`} className="px-2 pb-4" target="_blank">
            <img
              src={imgCall}
              className="h-8 w-8 md:h-10 md:w-10 object-contain"
            />
          </a>
        ) : null}
        {open && whatsappNumber ? (
          <a href={whatsappUrl} className="px-2 pb-4" target="_blank">
            <img
              src={imgWhats}
              className="h-8 w-8 md:h-10 md:w-10 object-contain"
            />
          </a>
        ) : null}
        {open && toAddress ? (
          <a href={mailtoUrl} className="px-2 pb-4" target="_blank">
            <img
              src={imgMail}
              className="h-8 w-8 md:h-10 md:w-10 object-contain"
            />
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default ContactWidget;
